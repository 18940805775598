import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: 'Home',
    component: () => import('@views/Home/index.vue')
  },
  {
    name: 'TeacherInfo',
    path: '/teacher/p/:id',
    component: () => import('@views/Teacher/info.vue')
  },
  {
    name: 'Teachers',
    path: '/teachers',
    component: () => import('@views/Teacher/index.vue')
  }
];
const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});
export default router;
