<template>
  <div id="app">
    <header class="header-wrap">
      <a href="#/">
        <img src="@assets/logo.png" alt="" />
      </a>
    </header>
    <div class="main-content">
      <router-view />
    </div>
    <div class="footer">
      <div class="footer-panel">
        <div class="footer-panel-left">
          <!-- 17537188153、18037574100 -->
          <p class="Line-space">客服电话： 400-005-6666</p>
          <p class="Line-space">
            链接：
            <a
              data-v-6eee5021=""
              href="https://pxxclass.com/download"
              target="_blank"
              class="a-block company-info-item"
              >平行课堂</a>
          </p>
          <!-- <p class="Line-space">
            <a href="http://beian.miit.gov.cn/" target="_blank"
              >粤ICP备2022068733号</a>
          </p> -->
          <div data-v-6eee5021="" class="company-info">
            <div data-v-6eee5021="" class="company-info-item a-block">
              广东省平行线在线教育培训中心
            </div>
            <span data-v-6eee5021="" class="mobile">|</span
            ><a
              data-v-6eee5021=""
              href="http://beian.miit.gov.cn"
              target="_blank"
              class="a-block company-info-item"
              >粤ICP备2022068733号</a
            ><span data-v-6eee5021="" class="mobile">|</span
            ><a
              data-v-6eee5021=""
              :href="require('./assets/营业执照.png')"
              target="_blank"
              class="a-block"
              >营业执照</a
            ><span data-v-6eee5021="" class="mobile">|</span
            ><a
              data-v-6eee5021=""
              :href="require('./assets/banxuexukezhen.png')"
              target="_blank"
              class="a-block"
              >办学许可证</a
            ><span data-v-6eee5021="" class="mobile">|</span
            ><span data-v-6eee5021=""
              >增值电信业务经营许可证[粤B2-20210631]</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="less" scoped>
#app {
  position: relative;
  .header-wrap {
    padding: 0 85px;
    height: 60px;
    background-color: #fff;
    display: flex;
    align-items: center;
    position: relative;
  }
  .footer {
    // min-width: 1280px;
    background: #f5f7f9;
    padding-top: 50px;
    a {
      color: #999;
    }
    .footer-panel {
      color: #aeb3b9;
      width: 1280px;
      padding: 20px 0;
      margin: 0 auto;
      border-top: 1px solid #e6e9ed;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      .Line-space {
        line-height: 22px;
        a {
          color: #999;
        }
      }

      a {
        text-decoration: none;
        color: #444;
        background: transparent;
        outline: none;
        cursor: pointer;
        transition: color 0.2s ease;
      }
    }
    .company-info {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 21px;
      // color: #999;
      // height: 47px;
      // border-top: 1px solid #ddd;
      span {
        margin: 0 5px;
      }
      a {
        color: #999;
      }
    }
  }
}
</style>
